import '@b2c/core/app/service/domain/user.js';
import '@b2c/core/app/service/domain/popup.js';
import '@b2c/core/app/service/rx/system$.js';

(function(){

    'use strict';

    const directive = { name: 'openLeagueFaq' };

    controller.$inject = [  'user', 'system$', 'popup', '$timeout' ];

    function controller( _user, _system$, _popup, $timeout  ){

        $timeout(() => {
            const lsKey = 'leagueFaqPopup'
            if (!window.localStorage.getItem(lsKey)) {
                _popup.open({ name: 'league-how' });

                window.localStorage.setItem( lsKey, 'true' );
            }

        }, 500)

        return {
            restrict: 'A'
        };
    }

    app.directive( directive.name, controller );

})();
