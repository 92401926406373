(function() {

    'use strict';

    const directive = { name: 'ngTapBackV2' };

    controller.$inject = [ 'state' ];

    function controller( _state ){

        const link = ( scope, element, attrs ) => {

            // let tapping = false;

            element.bind('tap', function(e) {
                if( window.history.length){
                    window.history.back();
                }else{
                    _state.goto( 'home' );
                }
            });

            // element.bind('touchstart', function(e) {
            //     tapping = false;
            // });

            // element.bind('touchstart', function(e) {
            //     if( tapping ){
            //         if( window.history.length){
            //             window.history.back();
            //         }else{
            //             _state.goto( 'home' );
            //         }
            //     }
            // });
            
        }

        return {
            link
        };
    }

    app.directive( directive.name, controller );

})();
