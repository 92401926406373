import '../../../service/domain/account.js'
(function () {
  controller.$inject = ['$scope', 'account'];

  function controller($scope, _account) {
    $scope.collection = [];

    this.$onInit = () => {
      const o = {
        count: this.count || 4,
      };

      $scope.preloader = true;
      _account
        .win(o)
        .then((a) => {
          $scope.collection = a.result;
        })
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    };
  }

  new Controller('userWin', controller, { count: '<' });
})();

(function () {
  [1, 2, 3, 4].forEach((i) => {
    new Batman(`batmanUserWin${i}`, 'userWin');
  });
})();
