(function(){
    'use strict';

    const lib = {
        '1':{avatar: '0', mask: '1'},
        '2':{avatar: '1', mask: '2'},
        '3':{avatar: '2', mask: '3'},
        '4':{avatar: '0', mask: '4'},
        '5':{avatar: '1', mask: '5'},
        '6':{avatar: '2', mask: '6'},
        '7':{avatar: '0', mask: '7'},
        '8':{avatar: '1', mask: '8'},
        '9':{avatar: '2', mask: '9'},
        '0':{avatar: '0', mask: '10'},
        'a':{avatar: '1', mask: '11'},
        'b':{avatar: '2', mask: '12'},
        'c':{avatar: '0', mask: '13'},
        'd':{avatar: '1', mask: '14'},
        'e':{avatar: '2', mask: '15'},
        'f':{avatar: '0', mask: '16'},
        'g':{avatar: '1', mask: '17'},
        'h':{avatar: '2', mask: '1'},
        'i':{avatar: '0', mask: '2'},
        'j':{avatar: '1', mask: '3'},
        'k':{avatar: '2', mask: '4'},
        'l':{avatar: '0', mask: '5'},
        'm':{avatar: '1', mask: '6'},
        'n':{avatar: '2', mask: '7'},
        'o':{avatar: '0', mask: '8'},
        'p':{avatar: '1', mask: '9'},
        'q':{avatar: '2', mask: '10'},
        'r':{avatar: '0', mask: '11'},
        's':{avatar: '1', mask: '12'},
        't':{avatar: '2', mask: '13'},
        'u':{avatar: '0', mask: '14'},
        'v':{avatar: '1', mask: '15'},
        'w':{avatar: '2', mask: '16'},
        'x':{avatar: '0', mask: '17'},
        'y':{avatar: '1', mask: '1'},
        'z':{avatar: '2', mask: '2'}
    };

    const directive = { name: 'timestamp' };

    controller.$inject = [ ];

    function controller(  ){


        function random(max){
            return Math.floor((Math.random() * max));
        }



        function link( scope, element, attrs ){
            scope.timestamp = random(1000)
        }
        return {
            restrict: 'A',
            link
        };
    }
    app.directive( directive.name, controller );
})();
