import '@b2c/core/app/service/domain/user.js';
import '@b2c/core/app/service/domain/league.js';
import ScrollMagic from 'scrollmagic';

(function () {
  'use strict';

  const directive = { name: 'leagueMeter' };

  controller.$inject = ['league', 'user'];

  function controller(_league, _user) {
    function link(scope, element, attrs) {
      let subscription;
      let CURRENT = 1;
      let points = 0;

      if (_user.status && scope.$eval(attrs[directive.name]) !== null) {
        CURRENT = scope.$eval(attrs[directive.name]).current || 1;
        points = scope.$eval(attrs[directive.name]).data.totalPoints || 0;
      }

      scope.leagues = {
        list: Array(8)
          .fill(0)
          .map((el, i, origin) => origin.length - i),
        current: CURRENT,
      };

      // init controller
      var controller = new ScrollMagic.Controller();
      let tileScenes = [];

      const elementHeight = 145;
      let sceneHeight = getSceneHeight();

      const seconds = 3;

      let inProgress = false;

      // build scene

      element[0].querySelector('#meter').style.height = sceneHeight + 'px';

      var scene = new ScrollMagic.Scene({
        // offset: 50,
        triggerElement: '#meter',
        duration: sceneHeight,
        triggerHook: 0,
        // triggerHook: 0.5
      })
        .addTo(controller)
        .on('progress', function (e) {
          const rawProgress = parseFloat(e.progress.toFixed(2));
          const triggerProgress = (rawProgress + 1) / 4;
          if (inProgress) {
            scene.triggerHook(triggerProgress);
            const pointerProg = triggerProgress * 1;
            tileScenes.forEach((tileScene) => tileScene.triggerHook(pointerProg));
            element[0].querySelector('#meter-progress').style.height = getProgressHeight() + 'px';
            element[0].querySelector('.league-pointer').style.bottom = getPointerHeight() + 'px';
          }
        });

      function getSceneHeight() {
        if (CURRENT === 8 || !_user.status) {
          return (scope.leagues.list.length - 1) * elementHeight + 40;
        } else {
          return (scope.leagues.list.length - 1) * elementHeight + 110;
        }
      }

      function getProgressHeight() {
        if (points === 0) {
          return 0;
        } else {
          if (CURRENT === 8) {
            return getSceneHeight();
          } else {
            return (CURRENT - 1) * elementHeight + 80;
          }
        }
      }

      function getPointerHeight() {
        if (points === 0) {
          return 0;
        } else {
          if (CURRENT === 8) {
            return (CURRENT - 1) * elementHeight + 8;
          } else {
            return (CURRENT - 1) * elementHeight + 80;
          }
        }
      }

      function finishScene(force) {
        if (inProgress && !force) {
          subscription = _league
            .onUpdate((data) => {
              let OLDCURRENT = CURRENT;
              CURRENT = data.currentPlayer ? data.currentPlayer.current : CURRENT;
              scope.leagues.current = CURRENT;
              sceneHeight = getSceneHeight();
              element[0].querySelector('#meter').style.height = sceneHeight + 'px';
              if (!inProgress && CURRENT !== OLDCURRENT) {
                finishScene(true);
                // controller.scrollTo('.league-' + CURRENT)
                controller.destroy();
              }
            })
            .subscribe();
        }
        if (inProgress || force) {
          if (scope.$eval(attrs[directive.name])) {
            points = scope.$eval(attrs[directive.name]).data.totalPoints || 0;
          }

          if (CURRENT < 3) {
            controller.scrollTo('.league-tile-4');
          } else if (CURRENT < 5) {
            controller.scrollTo('.league-tile-6');
          }

          controller.destroy();
          inProgress = false;

          scope.leagues.list.forEach((l) => {
            element[0].querySelector(`.league-tile-${l}`).classList.remove('league-active', 'league-reached');
            if (l <= CURRENT) {
              element[0].querySelector(`.league-tile-${l}`).className += ' league-reached';
            }

            if (l === CURRENT) {
              element[0].querySelector(`.league-reached`).className = element[0]
                .querySelector(`.league-reached`)
                .className.replace('league-active', '');
              element[0].querySelector(`.league-tile-${l}`).className += ' league-active';
            }

            element[0].querySelector('#meter-progress').style.height = getProgressHeight() + 'px';
            element[0].querySelector('.league-pointer').style.bottom = getPointerHeight() + 'px';
          });
        }
      }

      function initSmoothScroll() {
        // build tween
        var tween = TweenMax.from('#scrollTrigger', 1, {
          autoAlpha: 0.5,
          // scale: 0.7,
        });
        scene.setTween(tween);
      }

      setTimeout(() => {
        window.LEAGUE_CTRL = controller;
        tileScenes = scope.leagues.list.map((league) => {
          const tileScene = new ScrollMagic.Scene({
            duration: elementHeight,
            triggerElement: `.league-${league}`,
            offset: -elementHeight,
            triggerHook: 0,
          })
            .on('enter', (event) => {
              if (league === 1 || (league <= CURRENT && event.scrollDirection === 'REVERSE' && inProgress)) {
                element[0].querySelector(`.league-tile-${league}`).className += ' league-reached';
              }
            })

            .setClassToggle(`.league-tile-${league}`, 'league-active')
            .addTo(controller);
          return tileScene;
        });

        setTimeout(() => {
          inProgress = true;
          // scene.setPin(".league-pointer")
          setTimeout(() => {
            finishScene();
          }, 1000);
        }, 100);
      }, 100);

      scope.$on('$destroy', () => {
        subscription?.unsubscribe();
      });
    }
    return {
      restrict: 'A',
      link,
    };
  }
  app.directive(directive.name, controller);
})();
