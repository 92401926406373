(function () {

  'use strict';

  const directive = {name: 'closeSidebarWhenClickOutside'};

  controller.$inject = ['state', 'config'];

  function controller(scope, _config) {

    const link = ($scope, element, attrs) => {

      const el = document.querySelector('.game-page__sidebar-overlay');

      $scope.open = false;

      el.addEventListener('click', () => {
        $scope.sideMenu = '';
        $scope.open = false;
      });
    };

    return {
      restrict: 'A',
      link
    };
  }

  app.directive(directive.name, controller);

})();
