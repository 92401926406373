import "@b2c/core/app/application.js";
import "./directive/**/*";
import '@b2c/core/app/components/**/.DS_Store.js';
import '@b2c/core/app/components/**/batman-achievement-box1.js';
import '@b2c/core/app/components/**/batman-achievement-box2.js';
import '@b2c/core/app/components/**/batman-achievement-box3.js';
import '@b2c/core/app/components/**/batman-achievement-box4.js';
import '@b2c/core/app/components/**/batman-achievement-item1.js';
import '@b2c/core/app/components/**/batman-achievement-item2.js';
import '@b2c/core/app/components/**/batman-achievement-item3.js';
import '@b2c/core/app/components/**/batman-achievement-item4.js';
import '@b2c/core/app/components/**/batman-achievement-item5.js';
import '@b2c/core/app/components/**/batman-banking-box1.js';
import '@b2c/core/app/components/**/batman-banking-page1.js';
import '@b2c/core/app/components/**/batman-banner1.js';
import '@b2c/core/app/components/**/batman-banner10.js';
import '@b2c/core/app/components/**/batman-banner3.js';
import '@b2c/core/app/components/**/batman-banner5.js';
import '@b2c/core/app/components/**/batman-banner6.js';
import '@b2c/core/app/components/**/batman-banner7.js';
import '@b2c/core/app/components/**/batman-banner8.js';
import '@b2c/core/app/components/**/batman-banner9.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup1.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup2.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup3.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup4.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup5.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup6.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup7.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit-form1.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit-form2.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit-form3.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit1.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit2.js';
import '@b2c/core/app/components/**/batman-countdown1.js';
import '@b2c/core/app/components/**/batman-countdown2.js';
import '@b2c/core/app/components/**/batman-countdown3.js';
import '@b2c/core/app/components/**/batman-countdown4.js';
import '@b2c/core/app/components/**/batman-countdown6.js';
import '@b2c/core/app/components/**/batman-countdown7.js';
import '@b2c/core/app/components/**/batman-game-box-favourites1.js';
import '@b2c/core/app/components/**/batman-game-box-favourites2.js';
import '@b2c/core/app/components/**/batman-game-box1.js';
import '@b2c/core/app/components/**/batman-game-box10.js';
import '@b2c/core/app/components/**/batman-game-box11.js';
import '@b2c/core/app/components/**/batman-game-box12.js';
import '@b2c/core/app/components/**/batman-game-box2.js';
import '@b2c/core/app/components/**/batman-game-box3.js';
import '@b2c/core/app/components/**/batman-game-box4.js';
import '@b2c/core/app/components/**/batman-game-box5.js';
import '@b2c/core/app/components/**/batman-game-box6.js';
import '@b2c/core/app/components/**/batman-game-box7.js';
import '@b2c/core/app/components/**/batman-game-box8.js';
import '@b2c/core/app/components/**/batman-game-box9.js';
import '@b2c/core/app/components/**/batman-game-categories-collection1.js';
import '@b2c/core/app/components/**/batman-game-hall1.js';
import '@b2c/core/app/components/**/batman-game-item1.js';
import '@b2c/core/app/components/**/batman-game-item2.js';
import '@b2c/core/app/components/**/batman-game-item3.js';
import '@b2c/core/app/components/**/batman-game-item4.js';
import '@b2c/core/app/components/**/batman-game-item5.js';
import '@b2c/core/app/components/**/batman-game-item6.js';
import '@b2c/core/app/components/**/batman-game-item7.js';
import '@b2c/core/app/components/**/batman-game-item8.js';
import '@b2c/core/app/components/**/batman-info-page1.js';
import '@b2c/core/app/components/**/batman-modal-wrapper1.js';
import '@b2c/core/app/components/**/batman-modal-wrapper2.js';
import '@b2c/core/app/components/**/batman-popup-wrapper1.js';
import '@b2c/core/app/components/**/batman-popup-wrapper10.js';
import '@b2c/core/app/components/**/batman-popup-wrapper12.js';
import '@b2c/core/app/components/**/batman-popup-wrapper13.js';
import '@b2c/core/app/components/**/batman-popup-wrapper14.js';
import '@b2c/core/app/components/**/batman-popup-wrapper15.js';
import '@b2c/core/app/components/**/batman-popup-wrapper16.js';
import '@b2c/core/app/components/**/batman-popup-wrapper17.js';
import '@b2c/core/app/components/**/batman-popup-wrapper2.js';
import '@b2c/core/app/components/**/batman-popup-wrapper3.js';
import '@b2c/core/app/components/**/batman-popup-wrapper4.js';
import '@b2c/core/app/components/**/batman-popup-wrapper5.js';
import '@b2c/core/app/components/**/batman-popup-wrapper6.js';
import '@b2c/core/app/components/**/batman-popup-wrapper7.js';
import '@b2c/core/app/components/**/batman-popup-wrapper8.js';
import '@b2c/core/app/components/**/batman-popup-wrapper9.js';
import '@b2c/core/app/components/**/batman-promo-item1.js';
import '@b2c/core/app/components/**/batman-promo-item2.js';
import '@b2c/core/app/components/**/batman-promo-list1.js';
import '@b2c/core/app/components/**/batman-promo-list2.js';
import '@b2c/core/app/components/**/batman-promo-list3.js';
import '@b2c/core/app/components/**/batman-recently-played-list1.js';
import '@b2c/core/app/components/**/batman-recently-played-list2.js';
import '@b2c/core/app/components/**/batman-registration1.js';
import '@b2c/core/app/components/**/batman-search1.js';
import '@b2c/core/app/components/**/batman-shop-box1.js';
import '@b2c/core/app/components/**/batman-shop-box2.js';
import '@b2c/core/app/components/**/batman-shop-box3.js';
import '@b2c/core/app/components/**/batman-shop-box4.js';
import '@b2c/core/app/components/**/batman-shop-confirmation-popup1.js';
import '@b2c/core/app/components/**/batman-shop-confirmation-popup3.js';
import '@b2c/core/app/components/**/batman-shop-confirmation-popup4.js';
import '@b2c/core/app/components/**/batman-shop-confirmation-popup5.js';
import '@b2c/core/app/components/**/batman-shop-freespin-box1.js';
import '@b2c/core/app/components/**/batman-shop-freespin-box2.js';
import '@b2c/core/app/components/**/batman-shop-freespin-item1.js';
import '@b2c/core/app/components/**/batman-shop-freespin-item2.js';
import '@b2c/core/app/components/**/batman-shop-item1.js';
import '@b2c/core/app/components/**/batman-shop-item2.js';
import '@b2c/core/app/components/**/batman-shop-item4.js';
import '@b2c/core/app/components/**/batman-shop-item5.js';
import '@b2c/core/app/components/**/batman-shop-item6.js';
import '@b2c/core/app/components/**/batman-shop-item7.js';
import '@b2c/core/app/components/**/batman-shop-item8.js';
import '@b2c/core/app/components/**/batman-shop-item9.js';
import '@b2c/core/app/components/**/batman-slider1.js';
import '@b2c/core/app/components/**/batman-sports-feed-list1.js';
import '@b2c/core/app/components/**/batman-tournament-item1.js';
import '@b2c/core/app/components/**/batman-tournament-item2.js';
import '@b2c/core/app/components/**/batman-tournament-item3.js';
import '@b2c/core/app/components/**/batman-tournament-item4.js';
import '@b2c/core/app/components/**/batman-tournament-item5.js';
import '@b2c/core/app/components/**/batman-tournament-item6.js';
import '@b2c/core/app/components/**/batman-tournament-item8.js';
import '@b2c/core/app/components/**/batman-tournament-list1.js';
import '@b2c/core/app/components/**/batman-ui-wrapper1.js';
import '@b2c/core/app/components/**/batman-ui-wrapper2.js';
import '@b2c/core/app/components/**/batman-ui-wrapper3.js';
import '@b2c/core/app/components/**/batman-user-win1.js';
import '@b2c/core/app/components/**/batman-view-layer1.js';
import '@b2c/core/app/components/**/batman-view-layer2.js';
import '@b2c/core/app/components/**/batman-view-layer3.js';
import '@b2c/core/app/components/**/batman-view-layer4.js';
import '@b2c/core/app/components/**/batman-view-layer5.js';
import '@b2c/core/app/components/**/batman-view-layer6.js';
import '@b2c/core/app/components/**/batman-view-layer7.js';
import '@b2c/core/app/components/**/batman-view-layer8.js';
import '@b2c/core/app/components/**/batman-widget-latest-jackpot1.js';
import '@b2c/core/app/components/**/batman-zimpler-paynplay-widget1.js';
import '@b2c/core/app/components/**/dash-tournament.js';
import '@b2c/core/app/components/**/funid-auth-flow1.js';
import '@b2c/core/app/components/**/funid-auth-flow2.js';
import '@b2c/core/app/components/**/game-page-side-menu.js';
import '@b2c/core/app/components/**/game-page-sidebar-shop.js';
import '@b2c/core/app/components/**/game-page-sidebar-tournaments.js';
import '@b2c/core/app/components/**/linda-achievements-page.js';
import '@b2c/core/app/components/**/linda-app.js';
import '@b2c/core/app/components/**/linda-article-box.js';
import '@b2c/core/app/components/**/linda-autobonus-popup.js';
import '@b2c/core/app/components/**/linda-bonus-activation-modal.js';
import '@b2c/core/app/components/**/linda-bonus-cancellation-modal.js';
import '@b2c/core/app/components/**/linda-cashbox-balance.js';
import '@b2c/core/app/components/**/linda-cashbox-deposit.js';
import '@b2c/core/app/components/**/linda-cashbox-deposit-form.js';
import '@b2c/core/app/components/**/linda-cashbox-deposit-form-funid.js';
import '@b2c/core/app/components/**/linda-cashbox-history.js';
import '@b2c/core/app/components/**/linda-cashbox-history-casino.js';
import '@b2c/core/app/components/**/linda-cashbox-history-deposit.js';
import '@b2c/core/app/components/**/linda-cashbox-history-withdraw.js';
import '@b2c/core/app/components/**/linda-cashbox-popup.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-cancel.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-form.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-form-funid.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-modal.js';
import '@b2c/core/app/components/**/linda-change-mask-popup.js';
import '@b2c/core/app/components/**/linda-christmas-mem-cards.js';
import '@b2c/core/app/components/**/linda-christmas-new-page.js';
import '@b2c/core/app/components/**/linda-cookie-banner.js';
import '@b2c/core/app/components/**/linda-error-modal.js';
import '@b2c/core/app/components/**/linda-find-object-days-list.js';
import '@b2c/core/app/components/**/linda-find-object-page.js';
import '@b2c/core/app/components/**/linda-find-object-popup-day-fail.js';
import '@b2c/core/app/components/**/linda-find-object-popup-day-start.js';
import '@b2c/core/app/components/**/linda-find-object-popup-day-success.js';
import '@b2c/core/app/components/**/linda-find-object-popup-game-finish.js';
import '@b2c/core/app/components/**/linda-footer.js';
import '@b2c/core/app/components/**/linda-funid-code.js';
import '@b2c/core/app/components/**/linda-game-cash-crab.js';
import '@b2c/core/app/components/**/linda-game-catalog.js';
import '@b2c/core/app/components/**/linda-game-categories.js';
import '@b2c/core/app/components/**/linda-game-field.js';
import '@b2c/core/app/components/**/linda-game-hall.js';
import '@b2c/core/app/components/**/linda-game-hall-providers.js';
import '@b2c/core/app/components/**/linda-game-item.js';
import '@b2c/core/app/components/**/linda-game-page.js';
import '@b2c/core/app/components/**/linda-game-page-mobile.js';
import '@b2c/core/app/components/**/linda-gdpr-registration-modal.js';
import '@b2c/core/app/components/**/linda-halloween-congrat-popup.js';
import '@b2c/core/app/components/**/linda-halloween-days.js';
import '@b2c/core/app/components/**/linda-halloween-page.js';
import '@b2c/core/app/components/**/linda-halloween-wheel.js';
import '@b2c/core/app/components/**/linda-header.js';
import '@b2c/core/app/components/**/linda-homepage.js';
import '@b2c/core/app/components/**/linda-icon-box.js';
import '@b2c/core/app/components/**/linda-img.js';
import '@b2c/core/app/components/**/linda-info-page.js';
import '@b2c/core/app/components/**/linda-info-popup.js';
import '@b2c/core/app/components/**/linda-lang-version-notification-modal.js';
import '@b2c/core/app/components/**/linda-leagues.js';
import '@b2c/core/app/components/**/linda-login-form.js';
import '@b2c/core/app/components/**/linda-login-popup.js';
import '@b2c/core/app/components/**/linda-mask-collection-box.js';
import '@b2c/core/app/components/**/linda-mask-collection-item.js';
import '@b2c/core/app/components/**/linda-mask-collection-page.js';
import '@b2c/core/app/components/**/linda-mask-collection-popup.js';
import '@b2c/core/app/components/**/linda-mask-collections.js';
import '@b2c/core/app/components/**/linda-mask-collections-info-popup.js';
import '@b2c/core/app/components/**/linda-mask-collections-page.js';
import '@b2c/core/app/components/**/linda-missed-data-cancellation-modal.js';
import '@b2c/core/app/components/**/linda-missed-data-form.js';
import '@b2c/core/app/components/**/linda-modal-body.js';
import '@b2c/core/app/components/**/linda-multiple-quick-deposit.js';
import '@b2c/core/app/components/**/linda-notification.js';
import '@b2c/core/app/components/**/linda-payment-callback.js';
import '@b2c/core/app/components/**/linda-popup-body.js';
import '@b2c/core/app/components/**/linda-profile-bonus-list.js';
import '@b2c/core/app/components/**/linda-profile-change-password-form.js';
import '@b2c/core/app/components/**/linda-profile-form.js';
import '@b2c/core/app/components/**/linda-profile-page.js';
import '@b2c/core/app/components/**/linda-profile-staff.js';
import '@b2c/core/app/components/**/linda-profile-win.js';
import '@b2c/core/app/components/**/linda-promo-notification-popup.js';
import '@b2c/core/app/components/**/linda-promo-page.js';
import '@b2c/core/app/components/**/linda-promos.js';
import '@b2c/core/app/components/**/linda-provider-catalog.js';
import '@b2c/core/app/components/**/linda-provider-list.js';
import '@b2c/core/app/components/**/linda-registration-page.js';
import '@b2c/core/app/components/**/linda-restoration-form.js';
import '@b2c/core/app/components/**/linda-restoration-new-pass-form.js';
import '@b2c/core/app/components/**/linda-restoration-popup.js';
import '@b2c/core/app/components/**/linda-shop-deposit-popup.js';
import '@b2c/core/app/components/**/linda-shop-page.js';
import '@b2c/core/app/components/**/linda-shop-play-popup.js';
import '@b2c/core/app/components/**/linda-shop-public-wrapper.js';
import '@b2c/core/app/components/**/linda-sidebar.js';
import '@b2c/core/app/components/**/linda-sitemap.js';
import '@b2c/core/app/components/**/linda-slider-main.js';
import '@b2c/core/app/components/**/linda-sport-page.js';
import '@b2c/core/app/components/**/linda-timer.js';
import '@b2c/core/app/components/**/linda-toolbar.js';
import '@b2c/core/app/components/**/linda-tournament-page.js';
import '@b2c/core/app/components/**/linda-tournament-small-widget.js';
import '@b2c/core/app/components/**/linda-tournaments.js';
import '@b2c/core/app/components/**/linda-transitional-popup.js';
import '@b2c/core/app/components/**/linda-view-layer-eight.js';
import '@b2c/core/app/components/**/linda-view-layer-five.js';
import '@b2c/core/app/components/**/linda-view-layer-four.js';
import '@b2c/core/app/components/**/linda-view-layer-one.js';
import '@b2c/core/app/components/**/linda-view-layer-seven.js';
import '@b2c/core/app/components/**/linda-view-layer-three.js';
import '@b2c/core/app/components/**/linda-view-layer-two.js';
import '@b2c/core/app/components/**/linda-vip.js';
import '@b2c/core/app/components/**/linda-widget-event-list.js';
import '@b2c/core/app/components/**/linda-widget-favourites.js';
import '@b2c/core/app/components/**/linda-withdrawal-payment-callback.js';
import '@b2c/core/app/components/**/linda-zimpler-paynplay-login-popup.js';
import '@b2c/core/app/components/**/linda-zimpler-paynplay-page.js';
import '@b2c/core/app/components/**/linda-zimpler-registration-modal.js';
import '@b2c/core/app/components/**/not-found.js';
import '@b2c/core/app/components/**/pgw-payment-list.js';
import '@b2c/core/app/components/**/pgw-widget.js';
import '@b2c/core/app/components/**/registration-deposit-bonus.js';
import '@b2c/core/app/components/**/registration-deposit-form.js';
import '@b2c/core/app/components/**/registration-deposit-init-form.js';
import '@b2c/core/app/components/**/registration-deposit-login.js';
import '@b2c/core/app/components/**/registration-deposit-paysystems.js';
import '@b2c/core/app/components/**/registration-deposit-popup.js';
import '@b2c/core/app/components/**/wazamba-bonus-item1.js';
import '@b2c/core/app/components/**/wazamba-bonus-item2.js';
import '@b2c/core/app/components/**/wazamba-dashboard.js';
import '@b2c/core/app/components/**/wazamba-shop-confirmation-popup1.js';
import '@b2c/core/app/components/**/wazamba-shop-confirmation-popup2.js';
import '@b2c/core/app/components/**/christmas-countdown.js';
import '@b2c/core/app/components/**/christmas-promo.js';
import '@b2c/core/app/components/**/christmas-promo-mobile.js';
import '@b2c/core/app/components/**/christmas-promo-popup.js';
import '@b2c/core/app/components/**/christmas-tournament.js';
import '@b2c/core/app/components/**/desc-easter-promo-popup.js';
import '@b2c/core/app/components/**/dvs-alert.js';
import '@b2c/core/app/components/**/dvs-item.js';
import '@b2c/core/app/components/**/dvs-page.js';
import '@b2c/core/app/components/**/dvs-popup.js';
import '@b2c/core/app/components/**/easter-countdown.js';
import '@b2c/core/app/components/**/easter-promo.js';
import '@b2c/core/app/components/**/easter-promo-mobile.js';
import '@b2c/core/app/components/**/easter-promo-popup.js';
import '@b2c/core/app/components/**/footer-default.js';
import '@b2c/core/app/components/**/halloween-countdown.js';
import '@b2c/core/app/components/**/halloween-promo.js';
import '@b2c/core/app/components/**/halloween-promo-mobile.js';
import '@b2c/core/app/components/**/halloween-tournament.js';
import '@b2c/core/app/components/**/linda-otp-components.js';
import '@b2c/core/app/components/**/linda-promo-wrapper.js';
import '@b2c/core/app/components/**/oktoberfest-holiday-welcome-popup.js';
import '@b2c/core/app/components/**/summer-after-game-popup.js';
import '@b2c/core/app/components/**/summer-completed-popup.js';
import '@b2c/core/app/components/**/summer-countdown.js';
import '@b2c/core/app/components/**/summer-countdown-short.js';
import '@b2c/core/app/components/**/summer-countdown-with-day.js';
import '@b2c/core/app/components/**/summer-exit-game-popup.js';
import '@b2c/core/app/components/**/summer-extra-progress.js';
import '@b2c/core/app/components/**/summer-holiday-item.js';
import '@b2c/core/app/components/**/summer-holiday-list.js';
import '@b2c/core/app/components/**/summer-how-work-popup.js';
import '@b2c/core/app/components/**/summer-landscape.js';
import '@b2c/core/app/components/**/summer-promo-page.js';
import '@b2c/core/app/components/**/summer-promo-wrapper.js';
import '@b2c/core/app/components/**/summer-reward-after-popup.js';
import '@b2c/core/app/components/**/summer-story-tell-modal.js';
import '@b2c/core/app/components/**/summer-super-reward-popup.js';
import '@b2c/core/app/components/**/summer-welcome-popup.js';
import '@b2c/core/app/components/**/world-cup-countdown.js';
import '@b2c/core/app/components/**/world-cup-popup-mobile1.js';
import '@b2c/core/app/components/**/world-cup-popup-mobile2.js';
import '@b2c/core/app/components/**/world-cup-popup1.js';
import '@b2c/core/app/components/**/world-cup-popup2.js';
import '@b2c/core/app/components/**/world-cup-promo.js';
import '@b2c/core/app/components/**/world-cup-promo-mobile.js';
import '@b2c/core/app/components/**/world-cup-sport-feed-list.js';
import '@b2c/core/app/components/**/world-cup-styles.js';
import '@b2c/core/app/components/**/world-cup-templates.js';
import '@b2c/core/app/components/base.js';