import '@b2c/core/app/service/domain/http.js'
(function () {
  'use strict';

  const directive = { name: 'wazambaFunidToggle' };

  factory.$inject = ['$q', 'http'];

  function factory($q, _http) {
    function _getEntityInfo(){
        const defer = $q.defer(),
          alias = 'entityInfo',
          config = {
            meta: {
              type: 'api',
              alias,
            },
          };

        _http
          .get(alias, config)
          .then(({ data }) => {
            defer.resolve(data);
          })
          .catch(({ data }) => {
            defer.reject(data);
          });

        return defer.promise;
    };

    function link(scope) {
      scope.showFunId = false;

      _getEntityInfo()
        .then(({result}) => {
            if(result.options?.feature_flags?.funid){
                scope.showFunId = result.options?.feature_flags?.funid;
            }
            console.log('RESULT => ', result.options?.feature_flags?.funid);
        })
        .catch((e) => {console.error('Error ', e)})
        .finally(() => scope.preloader = false);
    }

    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();