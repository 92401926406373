(function(){

    'use strict';

    const directive = { name: 'wazDatepicker' };

    controller.$inject = [ ];

    function controller( ){


        function parse(date) {
            const m = date.getMonth()+1;
            const d = date.getDate();
            const result = `${date.getFullYear()}-${m>9?m:`0${m}`}-${d>9?d:`0${d}`}`;
            // console.log(result)
            return result;

        }

        const link = ( scope, element, attrs, ctrl ) => {
            const o = scope.$eval( attrs[directive.name] ) || {};

            const l = {
                min: attrs.min ? new Date( attrs.min ) : null,
                max: attrs.max ? new Date( attrs.max ) : null
            };

            if( o.minAge ){
                l.max = ( new Date() ).removeYears( o.minAge );
                element[0].setAttribute( 'max', l.max.toISODate() );
            }

            if( o.maxAge ){
                l.min = ( new Date() ).removeYears( o.maxAge );
                element[0].setAttribute( 'min', l.min.toISODate() );
            }

            if( !scope.ngModel && o.defaultAge ){
                scope.ngModel = ( new Date() ).removeYears( o.defaultAge ).toISODate();
            }

            ctrl.$parsers.push( d => {
                if( l.max && d > l.max ){
                    d = l.max;
                    // ctrl.$setViewValue( d.toISODate() );
                    ctrl.$setViewValue( parse(d) );
                    ctrl.$render();
                }
                if( l.min && d < l.min ){
                    d = l.min;
                    // ctrl.$setViewValue( d.toISODate() );
                    ctrl.$setViewValue( parse(d) );
                    ctrl.$render();
                }

                // return !d ? '' : d.toISODate()
                return !d ? '' : parse(d)
            } );

            ctrl.$formatters.push( d => new Date( d ) );

        };

        return {
            require: 'ngModel',
            link,
            scope: {
                ngModel: '='
            }
        };
    }

    app.directive( directive.name, controller );

})();
