import '../../../service/periodicals/easter.js';
import '../../../service/rx/easter$.js';
(function () {
  'use strict';

  const component = { name: 'lindaFindObjectPopupGameFinish' };

  controller.$inject = ['$scope', 'easter', 'easter$'];

  function controller($scope, _easter, _easter$) {
    let subscription;

    $scope.currentDay = _easter.today;

    this.$onInit = () => {
      subscription = _easter$.subscribe((list) => {
        $scope.currentDay = _easter.today;
      });
    };

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
