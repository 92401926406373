(function(){

    'use strict';

    controller.$inject = [ '$state' ];

    function controller( $state ){

        function link( scope, element, attrs ){

            const variable = attrs.getPathToScope || 'stateParams';

            scope[variable] = $state.current.name;
            scope.$on( '$stateChangeSuccess', function( event, toState, toParams, fromState, fromParams ){

                scope[variable] = toState.name;
                
            } );
            

        }

        return {
            restrict: 'A',
            link: link
        };

    }

    app.directive( 'getPathToScope', controller );

})();

