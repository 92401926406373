(function(){

	'use strict';

	const directive = {
		name: 'hideElementToScroll'
	};

	controller.$inject = [];

	function controller( ){

		function link( scope, element ){

			const checkScroll = () => {

				const el = element[0];
				if (el.clientHeight === 0) {
					return
				}
				const isScrollable = el.clientHeight < el.scrollHeight;

				let onBottom = element[0].scrollTop + el.clientHeight >= el.scrollHeight;

				if (onBottom || !isScrollable) {
					document.querySelector(".lead_arrow").classList.add('hide-after');
				} else {
					document.querySelector(".lead_arrow").classList.remove('hide-after');
				}
			}

			element[0].onscroll = checkScroll

			setInterval( checkScroll, 500)
		}

		return {
			restrict: 'A',
			link,
		};
	}

	app.directive( directive.name, controller );

}());
