(function(){
  slideToScopeController.$inject = ['$scope']
  function slideToScopeController(scope) {
    const bindTo = scope.bindTo || 'slickCurrentIndex'
    scope.$watch('slideToScope', (index) =>{
      try {
        scope.$parent[bindTo] = index;
      } catch (e) {

      }
    })
  }

  app.directive('slideToScope', function() {
    return {
      restrict: 'A',
      controller: slideToScopeController,
      scope: {
        'bindTo': '@',
        'slideToScope' : '<'
      }
    }
  })
})();
