(function(){

    'use strict';

    controller.$inject = [ '$state' ];

    function controller( $state ){
        if ($state.params.lang == "fi"){
            $state.go( 'app.root.layer.fastdep' );
        }
    }

    app.directive( 'redirectFi', controller );

})();

