import '@b2c/core/app/service/rx/system$.js';
import '@b2c/core/app/service/domain/user.js';
import '@b2c/core/app/service/domain/promo.js';
import '@b2c/core/app/service/domain/tournament.js';
import '@b2c/core/app/service/domain/achievement.js';
import '@b2c/core/app/service/rx/achievement$.js';
import '@b2c/core/app/service/domain/bonus.js';
import '@b2c/core/app/service/domain/shop.js';
import '@b2c/core/app/service/rx/shop$.js';
import '@b2c/core/app/service/domain/game.js';
import '@b2c/core/app/service/domain/account.js';
import $ from "jquery"

(function(){

    'use strict';

    const component = { name: 'wazambaDashboard' };

    controller.$inject = [ '$scope', 'promo', 'achievement', "achievement$", "system$", "tournament", "bonus", "user", "shop", "shop$", 'game', 'account', '$q'];

    function controller( $scope, _promo, _achievement, _achievement$, _system$, _tournament, _bonus, _user, _shop, _shop$, _game, _account, $q){


        $scope.promos = {};

        $scope.achievements = [];

        $scope.tournament = [];
        $scope.bonus = [];
        $scope.shopExchange = {};
        $scope.shopFs = {};
        $scope.shopMask = {};
        $scope.gameLast = [];
        $scope.gameWin = [];

        let subscription;
        let subscriptionShop;

        function getElementIndex(target) {
            const arr = document.querySelectorAll('.dash-slider__item');
            let el = document.getElementsByTagName(`${target}`)[0];
            return [].indexOf.call(arr, el);
        }

        $(document).ready(function () {
            $('.dash-slider__wrap')
                .on('init', function () {
                }).slick({
                infinite: false,
                slidesToShow: 2,
                fade: false,
                arrows: false,
                dots: true,
                variableWidth: true,
                slidesToScroll: 1,
                mobileFirst: true
            });
        });

        function getAchievements() {
            _achievement
                .lastEarned({})
                .then( a => {
                    $scope.achievements = a.result.filter( item => item.status === 2);
                    let index =  getElementIndex('batman-achievement-item5');

                    if (!$scope.achievements.length) {$('.dash-slider__wrap').slick('slickRemove', index);}
                })
                .catch( e => { } )
                .finally( () => {} );
        }

        subscription = _achievement$.filter(data => data.action === 'take').subscribe( data => {
            getAchievements();
            $('.dash-slider__wrap').slick('reinit');
        } );
        subscriptionShop = _shop$.filter(data => data.action === 'buy').subscribe( data => {
            shopExchange();
            shopFs();
            shopMask();
            $('.dash-slider__wrap').slick('reinit');
        } );

        this.$onInit = () => {
            shopExchange();
            shopFs();
            shopMask();
            getAchievements();
        };

        this.$onDestroy = ()=> {
            subscription.unsubscribe();
            subscriptionShop.unsubscribe();
        };

        function sortTournament(a, b) {
            let item1 = moment(a.end, ['DD-MM-YYYY']).format('X');
            let item2 = moment(b.end, ['DD-MM-YYYY']).format('X');

            return item1 - item2;
        }

        _tournament
            .list({})
            .then(a => {
                const activeTournaments = a.result.filter(tournament => tournament.status === 1);
                let userTournament = [];
                let listTournament = [];


                $q.all(activeTournaments.map(item => _tournament.item({name: item.name})))
                    .then(item => {
                        item
                            .map(item => item.result)
                            .sort(sortTournament)
                            .forEach( item => {
                                item.currentUser ? userTournament.push(item) : listTournament.push(item);
                            });

                        $scope.tournament = userTournament[0] ? userTournament : listTournament;

                    });


            })
            .catch(e => {})
            .finally(() => {});

        _bonus
            .list({})
            .then(a => {
                a.result.filter( function (item) {
                    if(item.type === "freespin") {
                        item.status === 'active' ? $scope.bonus.unshift(item) : $scope.bonus.push(item);
                    }
                } );
                if (!$scope.bonus.length) {
                    $('.dash-slider__wrap').slick('slickRemove', getElementIndex('batman-bonus-spin-item3'));
                }
            })
            .catch(e => {})
            .finally(() => {});

        function shopExchange() {
            _shop
                .list({category: 'exchange'})
                .then(a => {
                    let result = a.result[0];
                    a.result.forEach(function
                        (item) {
                        if(_user.profile.coins >= item.price) {
                            result = item;
                        }
                    });
                    $scope.shopExchange = result;
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {});

        }

        function shopFs() {
            _shop
                .list({category: 'freespin'})
                .then(a => {
                    let arrShop = a.result.sort(function (a, b){
                        return a.price - b.price;
                    });
                    let result = arrShop[0];

                    arrShop.forEach(function (item) {
                        if(_user.profile.coins >= item.price) {
                            result = item;
                        }
                        $scope.shopFs = result;
                    });
                    $scope.shopFs = result;

                    $scope.shopFs = result;
                    console.log("scope.shopFs", $scope.shopFs);
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {});

        }

        function shopMask() {
            _shop
                .list({category: 'mask'})
                .then(a => {
                    let result = a.result[0];
                    a.result.forEach(function
                        (item) {
                        if(_user.profile.coins >= item.price) {
                            result = item;
                        }
                    });
                    $scope.shopMask = result;
                })
                .catch(e => {
                    console.error(e);
                })
                .finally(() => {});

        }

        _promo
            .list({category: 'dashboard_bonus'})
            .then( a => {
                $scope.promos = _promo.result.find(item => item.alias === "welcome-bonus");
            })
            .catch( e => {} )
            .finally( () => {} );

        _game
            .recentlyPlayed()
            .then( a => {
                $scope.gameLast = a.result;
                if(!$scope.gameLast.length) {
                    $('.dash-slider__wrap').slick('slickRemove', getElementIndex('batman-recently-played-list1'));
                }
            })
            .catch(e => {} )
            .finally( () => {});

        _account
            .win()
            .then(a=>{
                $scope.gameWin = a.result;
                if(!$scope.gameWin[0].amount) {
                    $('.dash-slider__wrap').slick('slickRemove', getElementIndex('batman-user-win1'));
                }
            })
            .catch(e => {} )
            .finally( () => {});
    }

    app.component( component.name, {
        controller,
        templateUrl: app.getTU( component.name )
    });


})();
