import '@b2c/core/app/service/rx/system$.js';
import '@b2c/core/app/service/domain/league.js';
import '@b2c/core/app/service/rx/ws$.js';
import '@b2c/core/app/service/domain/icon.js';
import '@b2c/core/app/service/configs/ws-events.js';
import '@b2c/core/app/service/domain/user.js';
import '@b2c/core/app/service/domain/popup.js';

(function () {
  'use strict';

  const directive = { name: 'getLeagueToScope' };

  factory.$inject = ['league'];
  function factory(_league) {
    function link(scope, element, attrs) {
      const variable = attrs[directive.name] || 'league';

      scope[variable] = null;

      scope._leagueData = null;

      const subscription = _league.onUpdate((data) => {
        scope[variable] = data;
      });

      scope.$on('$destroy', () => {
        subscription?.unsubscribe();
      });
    }
    return {
      restrict: 'A',
      link,
    };
  }

  app.directive(directive.name, factory);
})();
