(function(){

    'use strict';

    controller.$inject = [ '$document' ];

    function controller( $document ){

        function link(){

            setTimeout(function(){
                
                let position = document.getElementsByClassName('profile-tabs')[0].offsetTop - 82;
                $document.scrollTopAnimated( position );
                document.getElementsByClassName('profile-tabs')[0].scrollLeft = 2000;

            },500);
               
        }

        return {
            restrict: 'A',
            link: link
        };

    }

    app.directive( 'scrollToLeft', controller );

})();

