import '@b2c/core/app/service/domain/achievement.js'
import '@b2c/core/app/service/rx/achievement$.js'
import '@b2c/core/app/service/rx/user$.js'
import { filter } from "rxjs/operators";
(function(){

  'use strict';

  controller.$inject = ['$state', 'achievement', 'achievement$', 'user$'];

  function controller( $state, _achievement, _achievement$, _user$) {
    let user = {status: false};
    function link( scope, element, attrs ){
      _user$.subscribe((data) => {
        user = data;
        getAchievements();
      });

      const init = () => {
        if(!user.status){
          return false;
        }
        return true;
      }

      const getAchievements = () => {

        if(!init()){
          return;
        }

        const variable = attrs['getCompletedAchievments'] || 'ach';

        scope.collection = [];
        scope.preloader = false;
        let achievementsCollection;

        const options = {
          count: 100,
        };

        let method = 'lastEarned';

        function getCollection() {
          if (achievementsCollection) {
            scope.collection = achievementsCollection;
            return;
          }
          scope.preloader = true;

          _achievement[method](options)
            .then(({ result }) => {
              scope[variable] = result.filter((i) => i.status === 2);
              scope[variable].grabed = result.filter((i) => i.status === 3);
            })
            .catch((e) => {})
            .finally(() => {
              scope.preloader = false;
            });
        }

        getCollection();

        _achievement$.pipe(
          filter((item) => item.action === 'take')
        ).subscribe((item) => {
            if (scope.collection.find((a) => a.id === item.achievement.id))
              getCollection();
          });
      }

      getAchievements();

    }

    return {
      restrict: 'A',
      link: link
    };

  }

  app.directive( 'getCompletedAchievments', controller );

})();
