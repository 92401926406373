import '../../../service/periodicals/easter.js';
import '../../../service/rx/easter$.js';
(function () {
  'use strict';

  const component = { name: 'lindaFindObjectPage' };

  controller.$inject = ['$scope', 'easter', 'easter$', '$timeout'];

  function controller($scope, _easter, _easter$, $timeout) {
    $scope.preloader = false;
    $scope.list = [];
    $scope.currentDay = {};

    let subscription;

    this.$onInit = () => {
      subscription = _easter$.subscribe((list) => {
        $scope.list = list;
        $scope.currentDay = _easter.today;
      });
      getList();
    };

    this.$onDestroy = () => {
      subscription.unsubscribe();
    };

    function getList() {
      $scope.preloader = true;
      _easter
        .list()
        .then((a) => {})
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    function finish(hash) {
      $scope.preloader = true;
      _easter
        .findEgg({ hash })
        .then((a) => {})
        .catch((e) => {})
        .finally(() => {
          $scope.preloader = false;
        });
    }

    $scope.notFindEgg = () => {
      finish(null);
    };

    $scope.findEgg = () => {
      finish($scope.currentDay.hash);
    };
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
