import $ from "jquery"

(function(){
    'use strict';

    const directive = { name: 'seoTextScroll' };

    controller.$inject = ['$document' ];

    function controller( $document ){



        function link( scope, element, attrs ){

            const padding = 60;
            $(element[0]).on('click', 'section h1, section h2, section h3', (event) => {
                const child = event.currentTarget;



                if( child ){
                    let position = $(child).offset().top - padding;
                    $document.scrollTopAnimated( position );
                }
                    
            })


        }
        return {
            restrict: 'A',
            link
        };
    }
    app.directive( directive.name, controller );
})();
