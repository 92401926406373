import '@b2c/core/app/service/domain/user.js';
import '@b2c/core/app/service/domain/league.js';
import ScrollMagic from 'scrollmagic';

(function () {
  'use strict';

  const directive = { name: 'leagueMeterInstant' };

  controller.$inject = ['league', 'user'];

  function controller(_league, _user) {
    function link(scope, element, attrs) {
      let subscription;
      let CURRENT = 1;
      let points = 0;

      if (_user.status && scope.$eval(attrs[directive.name]) !== null) {
        CURRENT = scope.$eval(attrs[directive.name]).current || 1;
        points = scope.$eval(attrs[directive.name]).data.totalPoints || 0;
      }

      scope.leagues = {
        list: Array(8)
          .fill(0)
          .map((el, i, origin) => origin.length - i),
        current: CURRENT,
      };

      // init controller
      var controller = new ScrollMagic.Controller();
      let tileScenes = [];

      const elementHeight = window.innerWidth < 540 ? 134 : 182;

      let sceneHeight = getSceneHeight();

      let inProgress = false;

      // build scene
      // let pointerScene;

      // pointerScene = new ScrollMagic.Scene({
      //     // triggerElement: ".league-pointer",
      //     triggerElement: "#meter",
      //     // offset: -100,
      // })
      //     .setPin(".league-pointer")
      //     // .addIndicators() // add indicators (requires plugin)
      //     .addTo(controller);

      element[0].querySelector('#meter').style.height = sceneHeight + 'px';

      var scene = new ScrollMagic.Scene({
        // offset: 50,
        triggerElement: '#meter',
        duration: sceneHeight,
        triggerHook: 0.5,
      });
      // .addTo(controller)
      // .on("progress", function (e) {
      //     // $("#progress").text(e.progress.toFixed(3));
      //     const rawProgress = parseFloat(e.progress.toFixed(2))
      //     const progress = (rawProgress - 1) * -1;
      //     const triggerProgress = (rawProgress + 1) / 4;
      //     if (inProgress) {
      //         scene.triggerHook(triggerProgress)
      //         const pointerProg = triggerProgress * 1

      //         // pointerScene.triggerHook( pointerProg )
      //         tileScenes.forEach(tileScene => tileScene.triggerHook(pointerProg));
      //         // const updatedHeight = (((sceneHeight) * progress) + (dpHeight * (1 - progress)));

      //         const updatedHeight = (sceneHeight * progress) + dpHeight;

      //         const currentHeight = finalHeight > updatedHeight ? updatedHeight : finalHeight;
      //         console.log({sceneHeight, updatedHeight, progress, triggerProgress})
      //         element[0].querySelector('#meter-progress').style.height = currentHeight + 'px';
      //         element[0].querySelector('.league-pointer').style.bottom = currentHeight + 'px';
      //     }
      // });

      function getSceneHeight() {
        // console.log( CURRENT === 8 || !_user.status)
        // if (CURRENT === 8 || !_user.status){
        //     console.log('log')
        //
        //     if (window.innerWidth < 540) {
        //       return scope.leagues.list.length * elementHeight;
        //     } else {
        //       return scope.leagues.list.length * elementHeight - 90;
        //     }
        //
        //     // return (scope.leagues.list.length - 1 ) * elementHeight + 40 - 11 * (CURRENT - 1);
        // } else {
        //     console.log('unlog')
        //     // return (scope.leagues.list.length - 1 ) * elementHeight + 170 - 11 * (CURRENT - 1);
        //   if (window.innerWidth < 540) {
        //     return scope.leagues.list.length * elementHeight;
        //   } else {
        //     return scope.leagues.list.length * elementHeight - 90;
        //   }
        // }

        if (CURRENT === 8 && _user.status) {
          if (window.innerWidth < 540) {
            return scope.leagues.list.length * elementHeight - 50;
          } else {
            return scope.leagues.list.length * elementHeight - 150;
          }
        }

        if (CURRENT !== 8 && _user.status) {
          if (window.innerWidth < 540) {
            return scope.leagues.list.length * elementHeight - 28;
          } else {
            return scope.leagues.list.length * elementHeight - 80;
          }
        }

        if (!_user.status) {
          if (window.innerWidth < 540) {
            return scope.leagues.list.length * elementHeight - 140;
          } else {
            return scope.leagues.list.length * elementHeight - 184;
          }
        }
      }

      function getProgressHeight() {
        if (points === 0) {
          return 0;
        } else {
          if (CURRENT === 8) {
            return getSceneHeight();
          } else {
            return (CURRENT - 1) * (elementHeight - 11) + 130;
          }
        }
      }

      function getPointerHeight() {
        if (points === 0) {
          return 0;
        } else {
          if (CURRENT === 8) {
            if (window.innerWidth < 540) {
              return (CURRENT - 1) * (elementHeight - 11) + 125;
            } else {
              return (CURRENT - 1) * (elementHeight - 11) + 81;
            }
          } else {
            return (CURRENT - 1) * (elementHeight - 11) + 110;
          }
        }
      }

      function finishScene(force) {
        if (inProgress && !force) {
          subscription = _league
            .onUpdate((data) => {
              let OLDCURRENT = CURRENT;
              CURRENT = data.currentPlayer ? data.currentPlayer.current : CURRENT;
              scope.leagues.current = CURRENT;
              sceneHeight = getSceneHeight();
              element[0].querySelector('#meter').style.height = sceneHeight + 'px';
              if (!inProgress && CURRENT !== OLDCURRENT) {
                finishScene(true);
                controller.scrollTo('.league-' + (CURRENT == 8 ? CURRENT : CURRENT + 1));
                controller.destroy();
              }
            })
            .subscribe();
        }
        if (inProgress || force) {
          if (scope.$eval(attrs[directive.name])) {
            points = scope.$eval(attrs[directive.name]).data.totalPoints || 0;
          }

          scope.leagues.list.forEach((l) => {
            if (CURRENT == l || CURRENT != 8) {
              controller.scrollTo(`.league-tile-${CURRENT + 1}`);
            } else {
              controller.scrollTo('.league-nav');
            }
          });

          // if (CURRENT <= 3) {
          //   controller.scrollTo('.league-tile-4')
          //   console.log('league-tile-4')
          // } else if (CURRENT <= 5) {
          //   controller.scrollTo('.league-tile-6')
          //   console.log('.league-tile-6')
          // }

          controller.destroy();
          inProgress = false;
          // pointerScene.removePin()
          // controller.scrollTo('.league-tile-' + ( CURRENT + 2 ))
          // controller.destroy()
          // inProgress = false;

          scope.leagues.list.forEach((l) => {
            element[0].querySelector(`.league-tile-${l}`).classList.remove('league-active', 'league-reached');
            if (l <= CURRENT) {
              element[0].querySelector(`.league-tile-${l}`).className += ' league-reached';
            }

            if (l === CURRENT) {
              element[0].querySelector(`.league-reached`).className = element[0]
                .querySelector(`.league-reached`)
                .className.replace('league-active', '');
              element[0].querySelector(`.league-tile-${l}`).className += ' league-active';
            }

            element[0].querySelector('#meter-progress').style.height = getProgressHeight() + 'px';
            element[0].querySelector('.league-pointer').style.bottom = getPointerHeight() + 'px';
          });
        }
      }

      function initSmoothScroll() {
        // change behaviour of controller to animate scroll instead of jump
        controller.scrollTo(function (newpos) {
          TweenMax.to(window, 1, {
            scrollTo: {
              y: newpos,
              offsetY: (window.innerHeight - elementHeight) / 2,
            },
            overwrite: 5,
            onComplete() {
              // controller.destroy()
              // inProgress = false;
            },
          });
        });
        // build tween
        var tween = TweenMax.from('#scrollTrigger', 0.5, {
          autoAlpha: 0.5,
          // scale: 0.7,
        });
        scene.setTween(tween);
        tileScenes = scope.leagues.list.map((league) => {
          const tileScene = new ScrollMagic.Scene({
            duration: elementHeight,
            triggerElement: `.league-${league}`,
            offset: -elementHeight,
            triggerHook: 0,
          })
            .on('enter', (event) => {
              if (league === 1 || (league <= CURRENT && event.scrollDirection === 'REVERSE' && inProgress)) {
                element[0].querySelector(`.league-tile-${league}`).className += ' league-reached';
              }
            })

            .setClassToggle(`.league-tile-${league}`, 'league-active')
            .addTo(controller);
          return tileScene;
        });
      }

      setTimeout(() => {
        // controller.scrollTo('.league-8')

        // initSmoothScroll()
        // controller.scrollTo('.league-1')
        window.LEAGUE_CTRL = controller;

        // setTimeout(() => {
        inProgress = true;
        // scene.setPin(".league-pointer")

        setTimeout(() => {
          finishScene();
        }, 100);
        // }, 1000)
      }, 1);

      scope.$on('$destroy', () => {
        subscription?.unsubscribe();
      });
    }
    return {
      restrict: 'A',
      link,
    };
  }
  app.directive(directive.name, controller);
})();
