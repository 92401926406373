(function () {

    'use strict';

    const directive = { name: 'toTopOnStateChange' };

    controller.$inject = [ '$state' ];

    function controller( $state ){

        function link( scope, element, attrs ){


            scope.$on( '$stateChangeSuccess', () => {
                if(typeof $state.current.params  === "undefined" || typeof $state.current.params.scrollTop === "undefined") {
                    document.scrollingElement.scrollTop = 0;
                }
            } );

        }


        return {
            restrict: 'A',
            link
        };
    }

    app.directive( directive.name, controller );

})();