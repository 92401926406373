import '../../service/domain/http.js';
import '../../service/rx/easter$.js';
(function () {
  'use strict';

  app.factory('easter', controller);

  controller.$inject = ['$q', 'http', 'easter$'];

  function controller($q, _http, _easter$) {
    const o = { list, startGame, findEgg, today: null };

    let _collection = [];

    Object.defineProperty(o, 'collection', {
      set(data) {
        _collection = data;
        o.today = data.find((item) => item.today === true) || {};
        o.today.roundTimeLeft = o.today.start_time * 1000 + 60000 - Date.now();
        _easter$.next(o.collection);
      },
      get() {
        return _collection;
      },
    });

    function list() {
      const defer = $q.defer(),
        alias = 'easterDaysList',
        config = {
          params: {},
          meta: {
            type: 'api',
            alias,
          },
        };

      _http.get(alias, config).then(
        (answer) => {
          o.collection = answer.data.result;
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        }
      );

      return defer.promise;
    }

    function startGame(data) {
      const defer = $q.defer(),
        alias = 'easterGameStart',
        config = {
          meta: { alias },
        };

      _http.post(alias, data, config).then(
        (answer) => {
          o.collection = answer.data.result;
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        }
      );

      return defer.promise;
    }

    function findEgg(data) {
      const defer = $q.defer(),
        alias = 'easterEggFound',
        config = {
          meta: { alias },
        };

      _http.post(alias, data, config).then(
        (answer) => {
          o.collection = answer.data.result;
          defer.resolve(answer.data);
        },
        (answer) => {
          defer.reject(answer.data);
        }
      );

      return defer.promise;
    }

    return o;
  }
})();
