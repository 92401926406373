// - This bonus item redirects user after successful activation of a bonus to
// - 'successAlias' from scope, if it exists, or to
// - an alias, that is stated in config.mjs "bonusSuccessAlias" object

import '@b2c/core/app/service/domain/bonus.js';
import '@b2c/core/app/service/domain/user.js';
import '@b2c/core/app/service/domain/modal.js';
import '@b2c/core/app/service/domain/state.js';

(function () {
  controller.$inject = ['$scope', 'bonus', 'user', 'modal', 'state'];

  function controller($scope, _bonus, _user, _modal, _state) {
    $scope.bonus = {};
    $scope.successAlias = '';
    $scope.preloader = false;

    $scope.activate = () => {
      if (angular.isNumber($scope.bonus.bonus_id)) {
        $scope.preloader = true;
        _bonus.activate($scope.bonus, resolveSuccessAlias())
          .finally(() => {
            $scope.preloader = false;
          })
      }
    };

    function resolveSuccessAlias() {
      if ($scope.bonus.type === 'freespin') return $scope.bonus.game.alias;
      if ($scope.successAlias) return $scope.successAlias;
      return $_CONFIG.bonusSuccessAlias[$scope.bonus.type];
    }

    $scope.cancel = () => {
      if (angular.isNumber($scope.bonus.bonus_id)) {
        $scope.preloader = true;
        _bonus.cancel($scope.bonus)
          .finally(() => {
            $scope.preloader = false
          });
      }
    };

    this.$onChanges = () => {
      $scope.bonus = this.bonus;
      $scope.successAlias = this.successAlias;
      $scope.style = this.style;
    };
  }

  new Controller('bonusItem', controller, {
    bonus: '<',
    successAlias: '<',
    style: '<',
  });
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`wazambaBonusItem${i}`, 'bonusItem');
  });
})();
