(function(){

    'use strict';

    const component = { name: 'dashTournament' };

    controller.$inject = [ '$scope' ];

    function controller( $scope ){

        $scope.tournament = {};

        this.$onChanges = () => {
            $scope.tournament = this.tournament;
        };
    }

    app.component( component.name, {
        controller,
        templateUrl: app.getTU( component.name ),
        bindings: {
            tournament: '<',
        }
    });


})();
