import '@b2c/core/app/service/domain/user.js';
import '@b2c/core/app/service/domain/league.js';
import ScrollMagic from 'scrollmagic';

(function () {
  'use strict';

  const directive = { name: 'leagueMeterSemi' };

  controller.$inject = ['league', 'user'];

  function controller(_league, _user) {
    function link(scope, element, attrs) {
      console.log('_user', _user.status, scope.$eval(attrs[directive.name]));
      let subscription;
      let CURRENT = scope.$eval(attrs[directive.name]) || 1;

      if (!_user.status) {
        CURRENT = 1;
      }

      scope.leagues = {
        list: Array(8)
          .fill(0)
          .map((el, i, origin) => origin.length - i),
        current: CURRENT,
      };
      // init controller
      var controller = new ScrollMagic.Controller();
      let tileScenes = [];

      const elementHeight = 140;
      console.log('elementHeight =>', elementHeight);
      const seconds = 3;
      const sceneHeight = (scope.leagues.list.length - 1) * elementHeight;

      let inProgress = false;

      let dpHeight;
      if (CURRENT === 8) {
        dpHeight = 0;
      } else {
        dpHeight = 15;
      }
      // build scene
      // let pointerScene;

      // pointerScene = new ScrollMagic.Scene({
      //     // triggerElement: ".league-pointer",
      //     triggerElement: "#meter",
      //     // offset: -100,
      // })
      //     .setPin(".league-pointer")
      //     // .addIndicators() // add indicators (requires plugin)
      //     .addTo(controller);

      let finalHeight = (CURRENT - 1) * elementHeight + dpHeight;
      var scene = new ScrollMagic.Scene({
        // offset: 50,
        triggerElement: '#meter',
        duration: sceneHeight,
        triggerHook: 0.5,
      });
      // .addTo(controller)
      // .on("progress", function (e) {
      //     // $("#progress").text(e.progress.toFixed(3));
      //     const rawProgress = parseFloat(e.progress.toFixed(2))
      //     const progress = (rawProgress - 1) * -1;
      //     const triggerProgress = (rawProgress + 1) / 4;
      //     if (inProgress) {
      //         scene.triggerHook(triggerProgress)
      //         const pointerProg = triggerProgress * 1

      //         // pointerScene.triggerHook( pointerProg )
      //         tileScenes.forEach(tileScene => tileScene.triggerHook(pointerProg));
      //         // const updatedHeight = (((sceneHeight) * progress) + (dpHeight * (1 - progress)));

      //         const updatedHeight = (sceneHeight * progress) + dpHeight;

      //         const currentHeight = finalHeight > updatedHeight ? updatedHeight : finalHeight;
      //         console.log({sceneHeight, updatedHeight, progress, triggerProgress})
      //         element[0].querySelector('#meter-progress').style.height = currentHeight + 'px';
      //         element[0].querySelector('.league-pointer').style.bottom = currentHeight + 'px';
      //     }
      // });

      function setProgressHeight(heightInPx) {
        element[0].querySelector('#meter-progress').style.height = heightInPx + 'px';
        element[0].querySelector('.league-pointer').style.bottom = heightInPx + 'px';
      }

      function finishScene(force) {
        console.log('FINISH SCENE', { force });

        if (inProgress && !force) {
          subscription = _league
            .onUpdate((data) => {
              let OLDCURRENT = CURRENT;
              CURRENT = data.currentPlayer ? data.currentPlayer.current : CURRENT;

              if (!inProgress && CURRENT !== OLDCURRENT) {
                finishScene(true);
                controller.scrollTo('.league-' + (CURRENT + 2));
                controller.destroy();
              }
            })
            .subscribe();
        }
        if (inProgress || force) {
          // pointerScene.removePin()
          controller.destroy();
          inProgress = false;
          finalHeight = (CURRENT - 1) * elementHeight + dpHeight;

          scope.leagues.list.forEach((l) => {
            if (l <= CURRENT) {
              element[0].querySelector(`.league-tile-${l}`).className += ' league-reached';
            }

            if (l === CURRENT) {
              element[0].querySelector(`.league-reached`).className = element[0]
                .querySelector(`.league-reached`)
                .className.replace('league-active', '');
              element[0].querySelector(`.league-tile-${l}`).className += ' league-active';
            }

            setProgressHeight(finalHeight);
          });
        }
      }

      function initSmoothScroll() {
        // change behaviour of controller to animate scroll instead of jump
        controller.scrollTo(function (newpos) {
          TweenMax.to(window, seconds, {
            scrollTo: {
              y: newpos,
              offsetY: (window.innerHeight - elementHeight) / 2,
            },
            overwrite: 5,
            onComplete() {
              // controller.destroy()
              // inProgress = false;
            },
          });
        });
        // build tween
        var tween = TweenMax.from('#scrollTrigger', 0.5, {
          autoAlpha: 0.5,
          // scale: 0.7,
        });
        scene.setTween(tween);
        tileScenes = scope.leagues.list.map((league) => {
          const tileScene = new ScrollMagic.Scene({
            duration: elementHeight,
            triggerElement: `.league-${league}`,
            offset: -elementHeight,
            triggerHook: 0,
          })
            .on('enter', (event) => {
              if (league === 1 || (league <= CURRENT && event.scrollDirection === 'REVERSE' && inProgress)) {
                element[0].querySelector(`.league-tile-${league}`).className += ' league-reached';
              }
            })

            .setClassToggle(`.league-tile-${league}`, 'league-active')
            .addTo(controller);
          return tileScene;
        });
      }

      element[0].querySelector('#meter').style.height = sceneHeight + 'px';
      // document.querySelector(`ui-view`).className +=  ' league-block';

      setTimeout(() => {
        setProgressHeight(0);
        // controller.scrollTo('.league-8')

        // initSmoothScroll()
        // controller.scrollTo('.league-1')
        window.LEAGUE_CTRL = controller;
        inProgress = true;
        // scene.setPin(".league-pointer")

        controller.scrollTo('.league-tile-' + (CURRENT + 2));

        setTimeout(() => {
          scope.$parent.preloader = false;
          scope.$parent.loaded = true;
          finishScene();
        }, 1000 * seconds);
      }, 1);

      scope.$on('$destroy', () => {
        subscription?.unsubscribe();
      });
    }
    return {
      restrict: 'A',
      link,
    };
  }
  app.directive(directive.name, controller);
})();
